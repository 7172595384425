<template>
  <v-fade-transition mode="out-in">
    <div
      v-if="isSelected"
      class="buzzer card-content d-flex flex-column"
    >
      <v-btn 
        icon 
        absolute
        fab
        small
        color="error"
        :disabled="reboot.btn.disabled"
        :loading="reboot.btn.loading"
        class="close-btn ma-4"
        @click="reboot.toggle = true;"
      >
        <v-icon>{{ icons.mdiCached }}</v-icon>
      </v-btn>

      <v-card
        class="ad-thumb ma-4 mb-0"
        elevation="2"
      >
        <v-img
          class="white--text align-end"
          src="@/assets/buzzer-avatar.svg"
        >
          <v-card-title
            class="title code ml-8"
          >
            {{ formatBuzzerCode(buzzer.code) }} (ID {{ buzzer.id }})
          </v-card-title>
          <icon-base
            width="16"
            height="16"
            icon-name="standby-light"
            class="standby-light secondary--text"
            :class="{ 'error--text': buzzerStatus }"
          >
            <standby-light />
          </icon-base>
        </v-img>
      </v-card>

      <v-expand-transition>
        <v-list 
          v-if="!toggleSaveBtn()"
          light
          class="partner ma-4 mb-0 py-0 rounded"
        >
          <v-list-item 
            v-if="buzzer.driver.id"
            @click="getDriver(buzzer.driver.id)"
          >
            <v-list-item-avatar>
              <v-img 
                v-if="buzzer.driver.photo"
                :src="buzzer.driver.photo" 
              />
              <icon-base
                v-else
                width="96"
                height="96"
                icon-name="avatar"
                class="avatar-default"
              >
                <avatar-default />
              </icon-base>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <b>{{ buzzer.driver.name }}</b>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ formatCPF(buzzer.driver.cpf) }} | {{ buzzer.driver.plate }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-subheader
            v-else
          >
            {{ $t('general.awaiting') }} {{ $t('general.partner') }}
          </v-subheader>
        </v-list>
      </v-expand-transition>

      <v-card-text>
        <v-card flat>
          <v-subheader>
            <v-icon 
              v-text="buzzerIcon(buzzer)"
              :color="statusLight(buzzer)"
              class="mr-2"
            />
            {{ $t('buzzers.tracking.title') }}
          </v-subheader>
          <v-list 
            dense
            class="pt-0"
          >
            <v-list-item 
              class="buzzer-info"
            >
              <v-list-item-content>
                <v-list-item-title>{{ $t('buzzers.tracking.last_location') }} <b>{{ fromNow(buzzer.updated) }}</b></v-list-item-title>
                <v-list-item-subtitle>{{ $moment(buzzer.updated).format('DD/MM HH:mm:ss') }}</v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ $t('buzzers.tracking.lat') }}/{{ $t('buzzers.tracking.lng') }}: {{ buzzer.position.lat ? buzzer.position.lat.toFixed(5) : '–' }}, {{ buzzer.position.lng ? buzzer.position.lng.toFixed(5) : '–' }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item 
              v-if="buzzer.uptime!==null"
              class="buzzer-info"
            >
              <v-list-item-content>
                <v-list-item-subtitle>{{ $t('buzzers.tracking.uptime') }}</v-list-item-subtitle>
                <v-list-item-title><b>{{ uptime }}</b></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item 
              v-if="buzzer.impression.audience!==null"
              class="buzzer-info"
            >
              <v-list-item-content>
                <v-list-item-subtitle>{{ $t('buzzers.tracking.impacts') }}</v-list-item-subtitle>
                <v-list-item-title><b>{{ buzzer.impression.audience }}</b></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            
            <v-list-item 
              class="buzzer-info"
            >
              <v-list-item-content>
                <v-list-item-title>{{ $t('buzzers.tracking.last_impression') }} <b>{{ fromNow(buzzer.impression.updated) }}</b></v-list-item-title>
                <v-list-item-subtitle>{{ $moment(buzzer.impression.updated).format('DD/MM HH:mm:ss') }}</v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ $t('buzzers.tracking.lat') }}/{{ $t('buzzers.tracking.lng') }}: {{ buzzer.impression.position.lat ? buzzer.impression.position.lat.toFixed(5) : '–' }}, {{ buzzer.impression.position.lng ? buzzer.impression.position.lng.toFixed(5) : '–' }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item 
              v-if="buzzer.file!==null"
              class="buzzer-info"
            >
              <v-list-item-content>
                <v-list-item-subtitle>{{ $t('buzzers.tracking.ad') }}</v-list-item-subtitle>
                <v-list-item-title><b>{{ buzzer.impression.file }}</b></v-list-item-title>
                <v-list-item-title><b>{{ buzzer.impression.id }}</b></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>

        <v-container 
          fluid
          class="pa-0 pt-4"
        >
          <v-row>
            <v-col cols="7">
              <v-select
                v-model="buzzer.mode"
                :items="allowedModes"
                :loading="fields.mode.loading"
                :disabled="fields.mode.disabled"
                :label="$t('buzzers.mode.label')"
                outlined
                color="primary"
                @change="setMode"
              />
            </v-col>
            <v-col>
              <v-switch 
                v-model="fields.diagnotics.value"
                :loading="fields.diagnotics.loading"
                :disabled="fields.diagnotics.disabled"
                true-value="ON"
                false-value="OFF"
                hide-details
                inset
                class="mt-1"
                @change="setMode('INFO_'+fields.diagnotics.value)"
              >
                <template v-slot:label>
                  {{ $t('general.info') }}
                </template>
              </v-switch>
            </v-col>
          </v-row>
          <v-select
            v-model="buzzer.status"
            :items="allowedStatus"
            :loading="fields.status.loading"
            :disabled="fields.status.disabled"
            :label="$t('buzzers.display.label')"
            outlined
            color="primary"
            class="my-n4"
            @change="setStatus"
          />
        </v-container>
        <v-form
          ref="form"
          class="buzzer-form mb-8 pb-8"
        >
          <v-text-field
            v-model="buzzer.control_number"
            :loading="fields.control_number.loading"
            :readonly="fields.control_number.readonly"
            :label="$t(fields.control_number.label)"
            @keyup="formChanged('control_number')"
            class="control"
          />

          <v-subheader class="primary--text text--darken-2 px-0">
            {{ $t('buzzers.network.title') }}
          </v-subheader>
          
          <v-text-field
            v-model="buzzer.network.ssid"
            :loading="fields.network.ssid.loading"
            :readonly="fields.network.ssid.readonly"
            :hint="fields.network.ssid.hint"
            :label="$t(fields.network.ssid.label)"
            @focus="$event.target.select()"
            class="control"
            dense
          />
          <v-text-field
            v-model="buzzer.network.password"
            :loading="fields.network.password.loading"
            :readonly="fields.network.password.readonly"
            :hint="fields.network.password.hint"
            :label="$t(fields.network.password.label)"
            @focus="$event.target.select()"
            class="control"
            dense
          />
          <v-text-field
            v-model="buzzer.network.ip_local"
            :loading="fields.network.ip_local.loading"
            :readonly="fields.network.ip_local.readonly"
            :hint="fields.network.ip_local.hint"
            :label="$t(fields.network.ip_local.label)"
            @focus="$event.target.select()"
            class="control"
            dense
          />
          <v-text-field
            v-model="buzzer.network.ip_publico"
            :loading="fields.network.ip_publico.loading"
            :readonly="fields.network.ip_publico.readonly"
            :hint="fields.network.ip_publico.hint"
            :label="$t(fields.network.ip_publico.label)"
            @focus="$event.target.select()"
            class="control"
            dense
          />

          <v-subheader class="primary--text text--darken-2 px-0">
            {{ $t('buzzers.display.label') }}
          </v-subheader>
          
          <v-text-field
            v-model="buzzer.display.rgb_solution"
            :loading="fields.display.rgb_solution.loading"
            :disabled="fields.display.rgb_solution.disabled"
            :label="$t(fields.display.rgb_solution.label)"
            @keyup="formChanged('display.rgb_solution')"
            class="control"
            dense
          />

          <v-text-field
            v-model="buzzer.display.chipset"
            :loading="fields.display.chipset.loading"
            :disabled="fields.display.chipset.disabled"
            :label="$t(fields.display.chipset.label)"
            @keyup="formChanged('display.chipset')"
            class="control"
            dense
          />

          <v-text-field
            v-model="buzzer.display.pixel_pitch"
            :loading="fields.display.pixel_pitch.loading"
            :disabled="fields.display.pixel_pitch.disabled"
            :label="$t(fields.display.pixel_pitch.label)"
            @keyup="formChanged('display.pixel_pitch')"
            class="control"
            dense
          />

          <v-list
            subheader
            two-line
            class="mx-n4 transparent"
          >
            <v-subheader class="primary--text text--darken-2">
              {{ $t('buzzers.warnings.title') }}
            </v-subheader>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle v-text="$t(fields.warnings.code.label)" />
                <v-list-item-title v-text="buzzer.warnings.code ? buzzer.warnings.code : '–'" />
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle v-text="$t(fields.warnings.messages.label)" />
                <v-list-item-title v-text="buzzer.warnings.messages.length > 0 ? buzzer.warnings.messages.toString() : '–'" />
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle v-text="$t(fields.warnings.notifiedAt.label)" />
                <v-list-item-title v-text="fromNow(buzzer.warnings.notifiedAt)" />
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-list
            subheader
            two-line
            class="mx-n4 transparent"
          >
            <v-subheader class="primary--text text--darken-2">
              {{ $t('buzzers.controller.title') }}
            </v-subheader>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle v-text="$t(fields.controller.mcu.label)" />
                <v-list-item-title v-text="buzzer.controller.mcu" />
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle v-text="$t(fields.controller.os.label)" />
                <v-list-item-title v-text="buzzer.controller.os" />
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle v-text="$t(fields.controller.db.label)" />
                <v-list-item-title v-text="buzzer.controller.db" />
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle v-text="$t(fields.controller.app.label)" />
                <v-list-item-title v-text="buzzer.controller.app" />
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle v-text="$t(fields.controller.ram_tot.label)" />
                <v-list-item-title v-text="buzzer.controller.ram_tot" />
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle v-text="$t(fields.controller.disk_tot.label)" />
                <v-list-item-title v-text="buzzer.controller.disk_tot" />
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-list
            subheader
            two-line
            class="mx-n4 transparent"
          >
            <v-subheader class="primary--text text--darken-2">
              {{ $t('buzzers.other.title') }}
            </v-subheader>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle v-text="$t(fields.other.tk_acesso.label)" />
                <v-list-item-title v-text="buzzer.other.tk_acesso" />
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle v-text="$t(fields.other.cod_acesso.label)" />
                <v-list-item-title v-text="buzzer.other.cod_acesso" />
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle v-text="$t(fields.other.code_version_owner.label)" />
                <v-list-item-title v-text="buzzer.other.code_version_owner" />
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle v-text="$t(fields.other.code_version_main.label)" />
                <v-list-item-title v-text="buzzer.other.code_version_main" />
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-form>
      </v-card-text>

      <v-fab-transition>
        <v-btn
          v-show="toggleSaveBtn()"
          color="primary"
          class="btn-save"
          dark
          fixed
          bottom
          left
          fab
          :loading="saveBtn.loading"
          :disabled="saveBtn.disabled"
          @click="updateBuzzer"
        >
          <v-icon>{{ icons.mdiCheck }}</v-icon>
        </v-btn>
      </v-fab-transition>
    
      <v-dialog
        v-model="reboot.toggle"
        transition="slide-y-reverse-transition"
        light
        content-class="confirm"
      >
        <v-card>
          <v-card-title>{{ $t('buzzers.reboot.title') }}</v-card-title>
          <v-card-text class="py-4">
            <p class="body-1">
              {{ $t('buzzers.reboot.text') }}
            </p>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="grey darken-2"
              text
              @click="reboot.toggle = false;"
            >
              {{ $t('general.cancel') }}
            </v-btn>
            <v-btn
              color="error"
              text
              :disabled="reboot.btn.disabled"
              :loading="reboot.btn.loading"
              @click="rebootBuzzer()"
            >
              {{ $t('buzzers.reboot.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-fade-transition>
</template>

<style>

  @media (min-width: 1000px) {
    #app .v-dialog {
        max-width: 30rem !important;
    }
  }

  @media (max-width: 800px) {
    #app .v-dialog {
        min-width: 75vw;
    }
  }

  .control {
    margin: 16px 0;
  }

  .buzzer hr {
    margin: 8px 0;
  }

  #app .v-dialog.confirm {
    max-width: 30vw !important;
  }

  .buzzer .v-image {
    max-height: 32vw;
    cursor: default;
  }
  .buzzer .buzzer-header {
    position: relative;
  }

  .buzzer .buzzer-avatar {
    border-radius: .25rem;
  }

  .buzzer .title.code {
    font-size: 1rem !important;
    line-height: 1.5rem;
    opacity: .5;
  }

  .buzzer .standby-light {
    position: absolute;
    bottom: 16px;
    left: 16px;
    width: 24px;
  }

  .buzzer .close-btn {
    top: .5rem;
    right: .5rem;
  }

  .card .btn-new, .card .btn-save {
    left: 280px !important;
    bottom: 32px !important;
  }

  #app .v-dialog .v-list {
    border-radius: 0 !important;
  }

  .buzzer .partner {
    position: relative;
    border-radius: .5rem;
    z-index: 3;
  }


</style>

<script>

  // Icons
  import {
    mdiClose,
    mdiCheck,
    mdiCached,
    mdiCog,
    mdiMagnify,
    mdiContactlessPaymentCircle as mdiBuzzer,
    mdiAlertCircle,
    mdiCircleMedium,
    mdiMapMarkerOff
  } from '@mdi/js'

  // Utilities
  import services from '@/services'
  import { sync } from 'vuex-pathify'
  import { mask } from 'vue-the-mask'


  export default {
    name: 'Buzzer',

    components: {
      IconBase: () => import('@/components/IconBase'),
      AvatarDefault: () => import('@/components/icons/AvatarDefault'),
      StandbyLight: () => import('@/components/icons/StandbyLight'),
    },

    data: () => ({
      icons: {
        mdiClose,
        mdiCheck,
        mdiCached,
        mdiCog,
        mdiMagnify,
        mdiBuzzer,
        mdiAlertCircle,
        mdiCircleMedium,
        mdiMapMarkerOff
      },
      saveBtn: {
        disabled: false,
        loading: false
      },
      buzzer: {},
      changed: [],
      fields: {
        status: {
          value: null,
          loading: false,
          disabled: false,
          items: [
            { 
              text: 'buzzers.display.on',
              value: 'ON',
              disabled: true
            },
            {
              text: 'buzzers.display.standby', 
              value: 'STB',
              disabled: true
            },
            { 
              text: 'buzzers.display.off',
              value: 'OFF',
              disabled: true
            },
          ],
        },
        mode: {
          value: null,
          loading: false,
          disabled: false,
          items: [
            {
              text: 'buzzers.mode.maintenance',
              value: 'MNT'
            },
            {
              text: 'buzzers.mode.test', 
              value: 'TST',
            },
            {
              text: 'buzzers.mode.operating',
              value: 'OPE',
            }
          ],
        },
        diagnotics: {
          value: 'OFF',
          loading: false,
          disabled: false,
        },
        control_number: {
          label: 'buzzers.asset_id',
          loading: false,
          disabled: false,
        },
        network: {
          ssid: {
            label: 'buzzers.network.ssid',
            loading: false,
            readonly: true,
          },
          password: {
            label: 'buzzers.network.password',
            loading: false,
            readonly: true,
          },
          ip_local: {
            label: 'buzzers.network.local_ip',
            loading: false,
            readonly: true,
          },
          ip_publico: {
            label: 'buzzers.network.public_ip',
            loading: false,
            readonly: true,
          }
        },
        display: {
          rgb_solution: {
            label: 'buzzers.display.controller',
            loading: false,
            disabled: false,
          },
          chipset: {
            label: 'buzzers.display.chipset',
            loading: false,
            disabled: false,
          },
          pixel_pitch: {
            label: 'buzzers.display.density',
            loading: false,
            disabled: false,
          },
        },
        warnings: {
          code: {
            label: 'buzzers.warnings.code',
            loading: false,
            readonly: true,
          },
          messages: {
            label: 'buzzers.warnings.messages',
            loading: false,
            readonly: true,
          },
          notifiedAt: {
            label: 'buzzers.warnings.notified_at',
            loading: false,
            readonly: true,
          },
        },
        controller: {
          mcu: {
            label: 'buzzers.controller.mcu',
            loading: false,
            readonly: true,
            hint: 'Este campo não pode ser alterado.',
          },
          os: {
            label: 'buzzers.controller.os',
            loading: false,
            readonly: true,
            hint: 'Este campo não pode ser alterado.',
          },
          db: {
            label: 'buzzers.controller.db',
            loading: false,
            readonly: true,
            hint: 'Este campo não pode ser alterado.',
          },
          app: {
            label: 'buzzers.controller.app',
            loading: false,
            readonly: true,
            hint: 'Este campo não pode ser alterado.',
          },
          ram_tot: {
            label: 'buzzers.controller.ram_tot',
            loading: false,
            readonly: true,
            hint: 'Este campo não pode ser alterado.',
          },
          disk_tot: {
            label: 'buzzers.controller.disk_tot',
            loading: false,
            readonly: true,
            hint: 'Este campo não pode ser alterado.',
          },
        },
        other: {
          code_version_owner: {
            label: 'buzzers.other.code_version_owner',
            loading: false,
            readonly: true,
            hint: 'Este campo não pode ser alterado.',
          },
          code_version_main: {
            label: 'buzzers.other.code_version_main',
            loading: false,
            readonly: true,
            hint: 'Este campo não pode ser alterado.',
          },
          tk_acesso: {
            label: 'buzzers.other.tk_acesso',
            loading: false,
            readonly: true,
            hint: 'Este campo não pode ser alterado.',
          },
          cod_acesso: {
            label: 'buzzers.other.cod_acesso',
            loading: false,
            readonly: true,
            hint: 'Este campo não pode ser alterado.',
          },
        }
      },
      reboot: {
        toggle: false,
        btn: {
          disabled: false,
          loading: false
        }
      },
      dictionary: {
        'controller.mcu': 'mcu',
        'controller.os': 'so',
        'controller.db': 'bd',
        'controller.app': 'app',
        'display.chipset': 'chipset',
        'display.pixel_pitch': 'pixel_pitch',
        'display.rgb_solution': 'rgb_solution',
        'network.ssid': 'ssid',
        'network.password': 'ssid_pwd',
        'control_number': 'nr_patrimonio'
      },
    }),

    computed: {
      views: sync('app/views'),
      loading: sync('app/views@loading'),
      view: sync('app/views@buzzers'),
      items: sync('buzzers/data@items'),
      selected: sync('buzzers/data@selected'),
      prototype: sync('buzzers/prototype'),
      driver: sync('drivers/selected'),
      user: sync('user/data'),
      toast: sync('app/toast'),

      uptime () {
        return this.$moment.duration(this.buzzer.up_time, "seconds").humanize();
      },

      buzzerStatus () {
        return this.isValid(this.buzzer) ? this.buzzer.status==='ON' ? false : true : false;
      },

      isSelected () {
        return _.has(this.buzzer, 'code');
      },

      // buzzer: {
      //   get () {
      //     return this.selected!==null ? this.items[this.selected] : null;
      //   },
      //   set (value) {
      //     return value;
      //   },
      // },

      allowedModes () {
        return _.map(this.fields.mode.items, (item) => {
          return {
            ...item,
            text: this.$t(item.text)
          }
        });
      },

      allowedStatus () {
        return _.map(this.fields.status.items, (item) => {
          if ((this.buzzer.status=='ON'&&(item.value=='STB'||item.value=='ON'))
            || (this.buzzer.status=='STB'&&(item.value=='STB'||item.value=='ON'))
            || (this.buzzer.status=='OFF'&&(item.value=='OFF'))) {
            item.disabled = false;
          }else{
            item.disabled = true;
          }
          return {
            ...item,
            text: this.$t(item.text)
          };
        });
      }
    },

    methods: {
      ...services,

      fromNow (date) {
        return (date!==null) ? this.$moment(date).fromNow() : '—';
      },

      formChanged (field){
        // log change
        if(this.changed.indexOf(field)===-1){
          this.changed.push(field);
        }
        console.log(this.changed);
      },

      toggleSaveBtn () {
        let b = false;
        if(this.changed.length>0){
          b = true;
        }
        return b;
      },

      toggleStandby (status) {
        switch (status) {
          case 'STB':
            this.buzzer.standby = 'STB_ON';
            break;
          case 'ON':
          case 'OFF':
          default:
            this.buzzer.standby = 'STB_OFF';
        }
      },

      updateView () {
        console.log('updateView');
        this.buzzer = Object.assign({}, this.buzzer, this.items[this.selected]);
        this.toggleStandby(this.buzzer.status);
        this.changed = [];
      },

      statusLight (buzzer) {
        let color = 'grey';
        const updated = buzzer.updated;
        const tracked = buzzer.impression.updated;
        let diff;
        if (buzzer.status=='ON') {
          if (updated&&tracked) {
            const diffTrack = this.$moment(updated).diff(tracked,'minutes');
            const diffImpression = this.$moment().diff(updated,'minutes'); 
            diff = diffImpression;
            if (diff<=15) {
              color = 'success';
            }else{
              color = 'warning';
            }
          }else{
            color = 'grey';
          }
        }else{
          color = 'error';
        }
        return color;
      },

      buzzerIcon (buzzer) {
        let icon;
        const position = buzzer.position.lat;
        if (buzzer.mode=='OPE') {
          icon = this.statusLight(buzzer) == 'success' ? position ? this.icons.mdiBuzzer : this.icons.mdiMapMarkerOff : buzzer.status == 'ON' ? this.icons.mdiAlertCircle : this.icons.mdiCircleMedium;
        }else{
          icon = buzzer.status == 'ON' ? position ? this.icons.mdiBuzzer : this.icons.mdiMapMarkerOff : this.icons.mdiCircleMedium;
        }
        return icon;
      },

      rebootBuzzer () {
        this.reboot.toggle = false;
        this.reboot.btn.loading = this.reboot.btn.disabled = true;

        const buzzer = this.buzzer.code;
        const username = this.user.username;
        const token = this.user.auth.token;

        this.$api.COM
          .get('/rebootbuzzeradmin/'+username+'/'+token+'/'+buzzer+'/?format=json')
          .then(response => {
            console.log('rebootBuzzer => ',response);
            if(response.data.retorno==200){
              this.buzzer.mode = this.fields.mode.value;
            }else if(response.data.retorno==401){
              this.getout();
              this.handleError(response.data.retorno, this.$t('errors.session_expired'));
            }else{
              this.fields.mode.value = this.buzzer.mode;
              this.handleError(response.data.retorno, this.$t('errors.unknown'));
            }
          })
          .catch(error => {
            this.handleError(error);
            this.fields.status.value = this.buzzer.status;
          })
          .finally(() => {
            this.reboot.btn.loading = this.reboot.btn.disabled = false;
          });

      },

      setStatus () {
        const status = this.fields.status.value;
        switch (status) {
          case 'OFF':
            this.fields.status.value = this.buzzer.status;
            this.toggleToast(
              true,
              'Status não permitido',
              5000,
              false,
            );
            return false;
            break;
          default:
            this.fields.status.loading = this.fields.status.disabled = true;

            let standby;
            if(status==='STB'){
              standby = 'STB_ON';
            }else{
              standby = 'STB_OFF';
            }
            const buzzer = this.buzzer.id;
            const username = this.user.username;
            const token = this.user.auth.token;

            this.$api.COM
              .get('/modostandbybuzzeradmin/'+username+'/'+token+'/'+buzzer+'/'+standby+'/?format=json')
              .then(response => {
                console.log('setStatus => ',response);
                if(response.data.retorno==200){
                  this.buzzer.status = response.data.statusDisplay;
                  this.items[this.selected].status = this.buzzer.status;
                  
                }else if(response.data.retorno==401){
                  this.getout();
                  this.handleError(response.data.retorno, this.$t('errors.session_expired'));
                }else{
                  this.buzzer.status = response.data.statusDisplay;
                  this.handleError(response.data.retorno, this.$t('errors.unknown'));
                }
                this.items[this.selected].status = this.buzzer.status;
                this.toggleStandby(this.buzzer.status);
              })
              .catch(error => {
                this.handleError(error);
                this.fields.status.value = this.buzzer.status;
              })
              .finally(() => {
                this.fields.status.loading = this.fields.status.disabled = false;
              });
        }
      },

      setMode (mode) {
        const buzzer = this.buzzer.id;
        if (mode.indexOf('INFO')>=0) {
          this.fields.diagnotics.loading = true;
        }else{
          this.fields.mode.loading = true;
          this.fields.mode.value = mode;
        }

        const username = this.user.username;
        const token = this.user.auth.token;

        this.$api.COM
          .get('/modobuzzeradmin/'+username+'/'+token+'/'+buzzer+'/'+mode+'/?format=json')
          .then(response => {
            console.log('setMode => ',response);
            if(response.data.retorno==200){
              if (mode.indexOf('INFO')==-1) {
                this.buzzer.mode = response.data.modeOperacao;
              }
            }else if(response.data.retorno==401){
              this.getout();
              this.handleError(response.data.retorno, this.$t('errors.session_expired'));
            }else if(response.data.retorno==408){
              this.handleError(response.data.retorno, this.$t('errors.timeout'));
            }else{
              this.buzzer.mode = response.data.modeOperacao;
              this.handleError(response.data.retorno, this.$t('errors.unknown'));
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            if (mode.indexOf('INFO')>=0) {
              this.fields.diagnotics.loading = false;
            }else{
              this.fields.mode.loading = false;
            }
          });
      },

      translateNotation(field) {
        return this.dictionary[field];
      },

      updateBuzzer () {
        this.saveBtn.loading = true;

        // iterate through changed fields and format accordingly
        let data = {};
        const changes = this.changed;
        for(var f in changes){
          if(changes[f].indexOf('.')>=0){
            var s = changes[f].split('.');
            var section = s[0];
            var field = s[1];

            switch (changes[f]) {
              default:
                data[this.translateNotation(changes[f])] = this.buzzer[section][field];
            }
          }else{
            switch (changes[f]) {
              default:
                data[this.translateNotation(changes[f])] = this.buzzer[changes[f]];
            }
          }
        }
        console.log(data);

        const username = this.user.username;
        const token = this.user.auth.token;
        const buzzer = this.buzzer.code;

        this.$api.COM({
          url: '/updatedeviceinfobycodigoadmin',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              useradmin: username,
              authTk: token,
              cod_device: buzzer,
              data: data
            }
          })
          .then(response => {
            console.log('updateBuzzer => ', response);
            if (response.data.retorno=='200') {
              this.toggleToast(
                true,
                this.$t('messages.update_success'),
                5000,
                false,
              );
              this.changed = [];
            }else if(response.data.retorno==401){
              this.getout();
              this.handleError(response.data.retorno, this.$t('errors.session_expired'));
            }else{
              this.handleError(response.data.retorno, this.$t('errors.unknown'));
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            this.saveBtn.loading = false;
          })
      },

      getDriver (id) {
        this.$router.push({ path: `/drivers/${id}` });
      },

    },

    created () {
      if (!this.isSelected) {
        this.buzzer = Object.assign({}, this.prototype);
      }
    },

    mounted () {
      this.updateView();
    },

    beforeDestroy () {
      this.selected = null;
    },

    directives: {
      mask,
    }
  }
</script>
